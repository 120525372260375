<template>
  <div class="container">
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/guangDongLenjoy/joyfulUse25/top1.png"
    />
    <div class="scroll_tips">
      <ul class="scroll_box">
        <li class="scroll_item">176****6142抢到了</li>
        <li class="scroll_item">131****7394抢到了</li>
        <li class="scroll_item">155****4549抢到了</li>
        <li class="scroll_item">130****5436抢到了</li>
        <li class="scroll_item">156****0054抢到了</li>
        <li class="scroll_item">171****5245抢到了</li>
        <li class="scroll_item">175****6540抢到了</li>
        <li class="scroll_item">185****0974抢到了</li>
      </ul>
    </div>
    <div class="form">
      <van-field
        v-model="form.phone"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />

      <template>
        <van-field
          v-model="form.code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="请输入验证码"
          class="input-code"
        >
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>

      <img
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/zj/enjoy_btn.gif"
        class="submit"
        @click="onSubmit"
      />
      <div class="checked">
        <van-checkbox
          checked-color="#fb663e"
          v-model="checked"
          icon-size="16px"
        >
          我已阅读并同意<span class="book" @click.stop="handleScollBottom"
            >《活动规则》</span
          >和<span class="book" @click.stop="showDialog"
            >《个人信息保护政策》</span
          >
        </van-checkbox>
      </div>
    </div>
    <div class="info" ref="guize">
      <div style="text-align: center">
        <br />&nbsp;<span style="font-size: large"><b>☛ 活动说明 ☚</b></span>
      </div>
      <br />一、产品介绍 <br />欢用25元10GB国内流量权益组合包（广东）-立即生效
      <br />二、适用范围
      <br />广东联通移网手机用户（达量限速用户、4G全国流量王8元套餐用户除外）
      <br />三、销售时间：2024年6月1日-2024年12月31日
      <br />四、产品内容：每月10GB国内通用流量（不含港澳台）、权益7选1：腾讯视频VIP会员-月卡、爱奇艺黄金VIP会员-月卡、优酷VIP会员-月卡、芒果TV会员-月卡、哔哩哔哩大会员-月卡、QQ音乐绿钻豪华版会员-月卡、网易云音乐黑胶VIP会员-月卡
      <br />五、业务规则：
      <br />（一）计费规则：订购立即生效，25元/月，自动按月续订自动扣费。
      <br />（二）办理规则：生效期间不可重复订购。 <br />（三）流量使用规则：
      <br />1、流量共享规则：主卡订购可共享副卡，副卡订购不可共享主卡；
      <br />2、流量结转规则：可结转至次月（退订当月的剩余流量除外）；
      <br />3、流量抵扣顺序：定向流量包&gt;流量多天包&gt;欢用25元10GB国内流量权益组合包（广东）-立即生效（本产品）&gt;套内流量；
      <br />4、流量解限规则：若号码处于限速状态，此产品内流量无法解除限速。
      <br />5、流量使用范围：本产品包含的国内流量指大陆地区通用流量，不含港澳台地区流量。
      <br />（四）退订路径：可退订。可登录中国联通APP-服务-已订业务-退订操作退订，或致电10010、或营业厅退订。退订当月月底生效，当月可继续兑换权益/使用流量。次月起不再扣取费用。
      <br />（五）互斥关系：不与其它叠加包互斥。
      <br />（六）赠款抵扣规则：赠款不可抵扣月费。
      <br />（七）权益领取：权益可通过
      <a href="https://u.10010.cn/qAvMe">https://u.10010.cn/qAvMe</a>
      ，或在中国联通APP【我的-领会员】查看领取。登录特权中心-我的卡券使用权益。权益领取资格当月有效。首次购买权益立即到账，未退订次月起每月2号前到账。产品内具体权益、兑换使用方式会根据合作方变动进行调整，具体均以权益领取页面展示为准。
      <br />（八）5G速率服务：享受5G下行峰值300Mbps速率服务。用户实际体验速率受网络环境、用户位置、同一时间上网用户数、终端、业务等因素的影响可能会低于最高速率。
      <br /><br />
    </div>
    <BaseDialogConfirm
      :width="716"
      :height="738"
      background="https://cdn.qiumo.net.cn/allsmarketmaterial/guangDongLenjoy/joyfulUse25/dialog.png"
      :visibility.sync="visibility"
      closeBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer29/dialog_submit_close.png"
      submitBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer29/dialog_submit_querendinggou.png"
    />
  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index";
import BaseDialogConfirm from "@/components/BaseDialog/confirm.vue";

export default {
  components: {
    BaseDialogConfirm,
  },
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        productNo: "303212013975",
        phone: "",
        characteristic: "",
        marketUrl: "",
        code: "", //绑定输入验证码框框
        sid: "",
        data: "",
        orderId: "",
      },
      checked: false,
      visibility: false,
    };
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    this.form = {
      ...this.form,
      phone,
      characteristic: from + reasonCode,
      marketUrl: encodeURIComponent(window.location.href),
    };
  },

  methods: {
    getAuthCode() {
      if (this.yzm_loading) return;
      var filterPhone = /^1[3456789]\d{9}$/;
      const { phone = "" } = this.form;
      if (!filterPhone.test(phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        // this.yzm_loading = true;
        // getVerificationCode(
        //   JSON.stringify({ ...this.form, appPackage: navigator.userAgent })
        // )
        //   .then((res) => {
        //     if (res.code !== 0) {
        //       this.$dialog.alert({ message: res.message || "验证码获取失败" });
        //     } else {
        //       this.form.data = res.data.data.data;
        //       this.form.orderId = res.data.data.orderId;
        //       // 设置倒计时秒
        //       this.sendAuthCode = false;
        //       this.auth_time = 120;
        //       let auth_timetimer = setInterval(() => {
        //         this.auth_time--;
        //         if (this.auth_time <= 0) {
        //           this.sendAuthCode = true;
        //           clearInterval(auth_timetimer);
        //         }
        //       }, 1000);
        //     }
        //   })
        //   .finally(() => {
        //     // 结束加载状态
        //     this.yzm_loading = false;
        //   });
      }
    },
    handleSubmit() {
      // this.$toast.loading({
      //   duration: 0,
      //   forbidClick: true,
      //   mask: true,
      //   message: "加载中...",
      // });
      // tradeOrder(JSON.stringify(this.form)).then((res) => {
      //   this.$toast.clear();
      this.visibility = false;
      //   const { phone = "" } = this.form;
      //   if (res.code != 0) {
      //     this.$toast({
      //       duration: 8000,
      //       message: res.message || "提交失败",
      //     });
      //   } else {
      //     this.$dialog.alert({
      //       title: "提交成功",
      //       message: "具体结果，请以短信提示为准",
      //       messageAlign: "center",
      //     });
      //   }
      // });
    },
    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else if (!this.checked) {
        this.$dialog.alert({ message: "请勾选协议" });
      } else {
        this.visibility = true;
        // this.handleSubmit();
      }
    },
    handleScollBottom() {
      this.$nextTick(() => {
        const top = this.$refs.guize.offsetTop;
        document.documentElement.scrollTop = document.body.scrollTop = top;
      });
    },
    showDialog() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "个人信息保护政策",
        message: `尊敬的客户：  
您好!
根据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。 为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息:
(1)事先获得客户的明确授权；
(2)根据有关的法律法规要求；
(3)按照相关司法机关和/或政府主管部门的要求；
(4)为维护社会公众的利益所必需且适当；
(5)为维护我公司或客户的合法权益所必需且适当。
`,
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.container {
  background-color: #e33c50;
  padding-bottom: 100px;
  .img {
    width: 100%;
  }

  .scroll_tips {
    width: 100%;
    margin: -10px auto 0;
    overflow: hidden;

    .scroll_box {
      height: 61px;
      animation: scroll 15s linear infinite;
      display: flex;

      .scroll_item {
        background-color: rgba(0, 0, 0, 0.44);
        border-radius: 40px;
        white-space: nowrap;
        width: 329px;
        text-align: center;
        font-weight: 500;
        flex-shrink: 0;
        line-height: 61px;
        font-size: 30px;
        color: white;
        margin-left: 62px;
      }

      @keyframes scroll {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-317.2%);
        }
      }
    }
  }
  .form {
    position: relative;
    .checked {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;

      ::v-deep .van-checkbox {
        width: auto;

        &__label {
          color: #fff;
        }
      }

      .book {
        font-weight: 400;
        // color: #f8f107;
      }
    }

    ::v-deep .van-cell {
      width: 636px;
      height: 88px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 26px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 46px;
      // border: solid 3px #b2b2b2;

      &.input-code {
        margin-top: 45px;

        .code {
          width: 229px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          color: #ff840d;
          font-size: 30px;
          font-weight: normal;
          border-left: solid 3px #ff840d;
          // background-color: #ff840d;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 33px;
        &::-webkit-input-placeholder {
          font-size: 33px;
          font-weight: 400;
          color: #969696;
        }
      }
    }

    .submit {
      width: 630px;
      display: block;
      margin: 50px auto 0;
      animation: scaleDraw 2s infinite;
      @keyframes scaleDraw {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }
  .info {
    border-style: solid;
    border-color: #f8c32e;
    border-radius: 30px;
    background-color: white;
    width: 674px;
    padding: 30px;
    margin: 50px auto 0;
    font-size: x-small;
    letter-spacing: 4px;
    color: black;
    text-align: justify;
    box-sizing: border-box;
  }

  ::v-deep .dialog-confirm {
    .footer {
      padding-top: 610px;
      align-items: center;

      .btn-close {
        width: 180px;
        padding-right: 80px;
      }

      .btn-submit {
        height: 72px;
        width: 215px;
      }
    }
  }
}
</style>